import './history-list.scss'

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    fetchContactHistoryContact,
    selectInteraction,
} from 'store/contactHistory/contactHistory.actions'
import { selectContactHistoryInstance } from 'store/contactHistory/contactHistory.selectors'
import { formatInteractionDate } from 'utils'
import { IInteraction } from 'views/Metrics/HistoricMetrics/types'

import InteractionInformation from '../../InteractionInformation'
import { constructDisplayName, constructTags, getInteractionId } from '../../utils'

const HistoryList: React.FC<{}> = () => {
    const dispatch = useDispatch()

    const { attributeSearchName, attributeSearchValue, interactions, selectedInteraction } =
        useSelector(selectContactHistoryInstance) ?? {}

    const handleOnFocus = (interaction: IInteraction) => {
        if (['SMS CAMPAIGN-MESSAGE', 'SMS MARKETING-MESSAGE'].includes(interaction.channelType)) {
            dispatch(
                selectInteraction({
                    attributeSearchName: attributeSearchName!,
                    attributeSearchValue: attributeSearchValue!,
                    selectedInteraction: interaction,
                }),
            )
            return
        }

        if (attributeSearchName && attributeSearchValue)
            dispatch(
                fetchContactHistoryContact(attributeSearchName, attributeSearchValue, interaction),
            )
    }

    const handleListItemNavigation = (e: React.KeyboardEvent<HTMLLIElement>) => {
        let nextElement: HTMLLIElement | null = null

        switch (e.key) {
            case 'ArrowDown':
                nextElement = e.currentTarget.nextElementSibling as HTMLLIElement | null
                break
            case 'ArrowUp':
                nextElement = e.currentTarget.previousElementSibling as HTMLLIElement | null
                break
        }

        if (nextElement) {
            e.preventDefault()
            nextElement.focus()
        }
    }

    const calculateTabIndex = (
        index: number,
        interactionAlreadySelected: boolean,
        isSelected: boolean,
    ) => {
        // Set the tab index to 0 if the current interaction is selected
        if (isSelected) return 0

        // Set the tab index of the first interaction to 0 if an interaction has not been selected yet, otherwise set it to -1
        if (index === 0) return interactionAlreadySelected ? -1 : 0

        // Set the tab index to -1 for all other interactions in the list
        return -1
    }

    return (
        <ul aria-label="history list" className="contact-history-history-list">
            {interactions?.map((interaction, index) => {
                const {
                    ID,
                    agentName,
                    connectedToAgentTimestamp,
                    connectedToSystemTimestamp,
                    channelType,
                    emailMessages,
                    messageId,
                    disconnectReason,
                    queueName,
                    initiationMethod,
                    customerEndpointAddress,
                    attributes,
                    initiationTimestamp
                } = interaction

                const endpointAddress = constructDisplayName(
                    channelType,
                    customerEndpointAddress,
                    attributes,
                    emailMessages?.find((message) => message.messageId === messageId)?.from,
                )
                // Use message ID as the unique identifier in the case of task interactions being rendered
                const interactionId = getInteractionId(interaction)
                const selectedInteractionId =
                    selectedInteraction && getInteractionId(selectedInteraction)
                const isSelected = selectedInteractionId == interactionId
                const agentDisplayName = agentName ?? 'No name found'
                const connectedTimestamp = connectedToAgentTimestamp ?? connectedToSystemTimestamp ?? initiationTimestamp
                const interactionDate = connectedTimestamp
                    ? formatInteractionDate(connectedTimestamp)
                    : 'No date found'
                const { subject, attachments } =
                    emailMessages?.find((emailMessage) => emailMessage.messageId === messageId) ||
                    {}
                // For keyboard navigation - when in the list, prevent the Tab key from being used to navigate
                // the list, but instead navigate to the interactive elements within the MainView component
                const tabIndex = calculateTabIndex(index, !!selectedInteraction, isSelected)

                return (
                    <InteractionInformation
                        id={`contact-history-list-${interactionId}-${ID}`}
                        tabIndex={tabIndex}
                        role="listitem"
                        key={`${interactionId}-${ID}`}
                        endpointAddress={endpointAddress}
                        queueName={queueName}
                        agentName={agentDisplayName}
                        date={interactionDate}
                        channelType={channelType}
                        tags={constructTags(interaction)}
                        focus={isSelected}
                        onFocus={() => handleOnFocus(interaction)}
                        onKeyDown={handleListItemNavigation}
                        subject={subject}
                        hasAttachments={!!attachments?.length}
                        disconnectWithNoAgentReason={!agentName ? disconnectReason : undefined}
                        initiationMethod={initiationMethod!}
                    />
                )
            })}
        </ul>
    )
}

export default HistoryList
