import { useEffect, useState } from 'react'
import useCall from 'hooks/redux/useCall'
import useChatConnections from 'hooks/redux/useChatConnections'
import useContact from 'hooks/redux/useContact'
import useContacts from 'hooks/redux/useContacts'
import useTaskConnections from 'hooks/redux/useTaskConnections'
import { Channel } from '@aws-sdk/client-connect'
import { IScreenPopConfig } from 'store/app/app.features'
import { AppFeatures, useGetFeatureConfig } from 'hooks/useHasFeature'

export const useScreenPop = () => {

    const [screenPopUrl, setScreenPopUrl] = useState<string>()
    const [windowReferences, setWindowReferences] = useState<Record<string,Window | null>>({})

    const getFeatureConfig = useGetFeatureConfig()
    const screenPopConfig: IScreenPopConfig = getFeatureConfig(AppFeatures.SCREEN_POP) ?? {}
    const screenPopAttributeKey = screenPopConfig.screenPopAttribute ?? 'sa-screen-pop-url'

    const call = useCall()
    const contact = useContact()
    const contacts = useContacts()
    const activeChats = useChatConnections('current')
    const activeTasks = useTaskConnections('current')

    const connectedChatIds = activeChats.map((chatConnection) => chatConnection.id)
    const connectedTaskIds = activeTasks.map((taskConnection) => taskConnection.id)
    const connectedVoiceId = call && !call.incoming ?
        contacts.find((contact) => contact.channel === Channel.VOICE)?.ID : undefined
    const connectedIds = [
        ...connectedChatIds,
        ...connectedTaskIds,
        ...connectedVoiceId ? [connectedVoiceId] : []
    ]
    // Stringified to use as a single dependency in the below useEffect
    const connectedIdsString = JSON.stringify(connectedIds)

    // Update screenPopUrl when selected contact or connected contacts change
    useEffect(() => {
        if (!contact) {
            setScreenPopUrl(undefined)
            return
        }
        if (!connectedIds.includes(contact.ID)) return

        const url = contact?.attributes?.[screenPopAttributeKey]
        if (url) {
            setScreenPopUrl(url)
        }
    }, [contact?.ID, connectedIdsString, contact?.attributes?.[screenPopAttributeKey]])

    // Cleanup old windows references
    useEffect(() => {
        const referenceIds = new Set(Object.keys(windowReferences));
        //@ts-ignore: Compiler cannot find difference method on set - may be related to build target?
        const keysToRemove = referenceIds.difference(new Set(connectedIds));
        //@ts-ignore
        keysToRemove.forEach((key) => delete windowReferences[key])
    }, [connectedIdsString])

    // Open screen pop when URL changes.
    useEffect(() => {
        if (!screenPopUrl || !contact) return

        const windowReferenceName = `app_smartagent_${contact.ID}`
        const existingWindow = windowReferences[windowReferenceName];

        if (existingWindow?.opener && !existingWindow.opener.closed) return

        if (screenPopUrl) {
            const winRef = window.open(screenPopUrl, contact?.ID)
            windowReferences[windowReferenceName] = winRef;
        }
    }, [screenPopUrl])

    return {screenPopUrl}

}


