import './top-bar.scss'

import useTaskConnections from 'hooks/redux/useTaskConnections'
import React from 'react'
import { useSelector } from 'react-redux'
import { ExternalIframeConfiguration } from 'store/app/app.state'
import RootState from 'store/state'
import User from 'views/Home/User'

import CapacityCard from 'components/CapacityCard'
import MissedContact from 'components/MissedContact'
import useCall from 'hooks/redux/useCall'
import useChatConnections from 'hooks/redux/useChatConnections'
import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import useMedia from 'hooks/useMedia'
import ContactsList from './ContactList'
import IncomingContact from './IncomingContact'
import LoginTimerPopUp from './loginTimerPopUp'
import { useScreenPop } from 'hooks/useScreenPop'

interface Props {
    pathname: string
    showIncoming: boolean
}

const Header: React.FC<Props> = ({ showIncoming }) => {
    const hasFeature = useHasFeature()
    const call = useCall()
    const [isMobile] = useMedia()
    const showCapacityCard = hasFeature(AppFeatures.AGENT_CAPACITY_CARD_DISPLAY)
    const incomingChat = useChatConnections('incoming')
    const incomingTask = useTaskConnections('incoming')
    const blockStatusChange = useSelector<RootState, boolean | undefined>(
        (state) => state.user?.statusControl?.blockStatusChange,
    )
    const externalIframeConfiguration = useSelector<
        RootState,
        ExternalIframeConfiguration | undefined
    >((state) => state.app.appConfig.externalIframeConfiguration)

    const {screenPopUrl} = hasFeature(AppFeatures.SCREEN_POP) ? useScreenPop() : {screenPopUrl: undefined}

    const getActive = () => {
        if (call && call.incoming)
            return {
                channel: 'VOICE' as const,
                call: call,
            }

        if (incomingChat)
            return {
                channel: 'CHAT' as const,
                chat: incomingChat,
            }

        if (incomingTask)
            return {
                channel: 'TASK' as const,
                task: incomingTask,
            }

        return
    }

    return (
        <>
            {externalIframeConfiguration && (
                <iframe
                    style={externalIframeConfiguration.isVisible ? {} : { display: 'none' }}
                    id="external-iframe"
                    src={externalIframeConfiguration.url}
                    title={externalIframeConfiguration.name}
                />
            )}
            <header className={'sa-top-bar'}>
                <LoginTimerPopUp />

                <div className={'sa-top-bar_status'}>
                    <User blockStatusChange={blockStatusChange} />
                </div>

                <div className={'sa-top-bar_wrapper'}>
                    {showIncoming && <IncomingContact active={getActive()} />}

                    <MissedContact />

                        {!isMobile ? <ContactsList /> : null}
                        {showCapacityCard ? <CapacityCard /> : null}
                        {isMobile ? <ContactsList /> : null}
                </div>
            </header>
        </>
    )
}

export default Header
