import { orderBy } from 'lodash'
import { getThreads } from 'services/api/api.thread'
import ContactState from 'store/contact/contact.state'
import { IStrObj, paramsToQueryString } from 'utils'
import { getEmailFromContactAttributes } from 'views/Metrics/HistoricMetrics/ContactHistory/utils'
import {
    ChannelCategory,
    ChannelCategoryMap,
    ChannelType,
    IInteraction,
} from 'views/Metrics/HistoricMetrics/types'
import { getChannelType } from 'views/Metrics/HistoricMetrics/utils'

import {
    channelsByCategory,
    messagingChannelFormatMapping,
    socialChannelFormatMapping,
} from './contactHistory.constants'
import { Sort } from './contactHistory.state'

import type { SAMessageType } from '@missionlabs/smartagent-chat-components-lib/dist/ChatMessage/chat-message.types'
import type { SocialChatJsonMessage } from '@missionlabs/smartagent-service-chat/dist/types/socialChatJsonMessage'
import type { AllThreadsAndMessages } from '@missionlabs/smartagent-service-thread'
import { AppSubFeatures } from 'store/app/app.features'

export type ChannelSubFeatures =
    | AppSubFeatures.CONTACT_HISTORY_SOCIAL
    | AppSubFeatures.CONTACT_HISTORY_VOICE
    | AppSubFeatures.CONTACT_HISTORY_WEBCHAT
    | AppSubFeatures.CONTACT_HISTORY_TASK
    | AppSubFeatures.CONTACT_HISTORY_MESSAGING
    | undefined

type SortMap = {
    [key in Sort]: { direction: 'asc' | 'desc'; field: string }
}

type MessageContent = string | SAMessageType | SocialChatJsonMessage

export const JSONParse = (string: string): [Error | undefined, any] => {
    try {
        const parsedContent = JSON.parse(string)
        return [undefined, parsedContent]
    } catch (error) {
        return [error as Error, undefined]
    }
}

export const transformMessageContent = (
    content: string,
    language: string = 'en',
): MessageContent => {
    let transformedContent: MessageContent

    const [error, parsedContent]: [Error | undefined, any] = JSONParse(content)

    // Narrow down to a string if error occurs
    if (error) {
        transformedContent = content
    }
    // Attempt to grab the message content using the language given
    else if (parsedContent[language]) {
        transformedContent = parsedContent[language] as SAMessageType
    } else {
        transformedContent = parsedContent as SocialChatJsonMessage
    }

    return transformedContent
}

export const constructQueryStringByChannel = (
    contact: ContactState,
    channelType: ChannelType,
    filterByAgentTags?: boolean,
): string => {
    // Build up the query parameters based on the channel type given. This will need extending as we
    // expand the Contact History feature to other channels
    let queryParameters: IStrObj = {}

    if (isChannelOfCategory(channelType, 'Social')) {
        const isSMSChannel = channelType.includes('SMS')
        queryParameters = {
            ...(!isSMSChannel && { channel: socialChannelFormatMapping[channelType] }),
            uniqueSocialId: contact.attributes['sa-customer-endpoint-id'],
            answered: 'true',
        }
    } else if (isChannelOfCategory(channelType, 'Messaging')) {
        const isChannelWithOutboundContacts = channelType === 'SMS SMS-DM'
        queryParameters = {
            channel: messagingChannelFormatMapping[channelType],
            socialHandle: contact.attributes['sa-customer-endpoint-address'],
            ...(!isChannelWithOutboundContacts && { answered: 'true' }),
        }
    } else if (isChannelOfCategory(channelType, 'Voice')) {
        const { customerEndpointAddress: phonenumberE164, channel } = contact
        if (phonenumberE164 !== 'anonymous') {
            queryParameters = { answered: 'true', phonenumberE164, channel }
        }
    } else if (isChannelOfCategory(channelType, 'Webchat')) {
        const chatEmail = getEmailFromContactAttributes(contact.attributes)
        if (chatEmail) {
            const { channel } = contact
            queryParameters = { answered: 'true', channel, chatEmail }
        }
    } else if (isChannelOfCategory(channelType, 'Task')) {
        const { channel } = contact
        const taskEmail = contact.attributes['sa-customer-endpoint-address']
        if (taskEmail) {
            queryParameters = { channel, taskEmail }
        }
    } else {
        queryParameters = { answered: 'true', contactID: contact.ID }
    }

    if (Object.keys(queryParameters).length && filterByAgentTags) {
        queryParameters.filterByAgentTags = 'true'
    }

    return paramsToQueryString(queryParameters)
}

export const sortContactHistoryInteractions = (
    originInteractions: IInteraction[] = [],
    sort: Sort,
): IInteraction[] => {
    const interactions = originInteractions.map(interaction => ({ 
        ...interaction, 
        connectedTimestamp: interaction.connectedToAgentTimestamp ?? interaction.connectedToSystemTimestamp ?? interaction.initiationTimestamp
    }))

    // Map given sort to a sort direction and field to sort by
    const sortMap: SortMap = {
        'date-time-asc': {
            direction: 'asc',
            field: 'connectedTimestamp',
        },
        'date-time-desc': {
            direction: 'desc',
            field: 'connectedTimestamp',
        },
        'agent-name-asc': {
            direction: 'asc',
            field: 'agentName',
        },
        'agent-name-desc': {
            direction: 'desc',
            field: 'agentName',
        },
        'queue-asc': {
            direction: 'asc',
            field: 'queueName',
        },
        'queue-desc': {
            direction: 'desc',
            field: 'queueName',
        },
        'duration-asc': {
            direction: 'asc',
            field: 'interactionDuration',
        },
        'duration-desc': {
            direction: 'desc',
            field: 'interactionDuration',
        },
        'acw-duration-asc': {
            direction: 'asc',
            field: 'afterContactWorkDuration',
        },
        'acw-duration-desc': {
            direction: 'desc',
            field: 'afterContactWorkDuration',
        },
    }

    const { field, direction } = sortMap[sort]
    return orderBy(interactions, field, direction)
}

export const transformContacts = (contacts: ContactState[]): Array<IInteraction> => {
    // Function to perform any needed transformations on the incoming interactions before being added to the contactHistory state
    return contacts.map((contact) => {
        const { disconnectTimestamp, connectedToAgentTimestamp, attributes = {} } = contact
        const interactionDuration =
            disconnectTimestamp && connectedToAgentTimestamp
                ? disconnectTimestamp - connectedToAgentTimestamp
                : 0
        const channelType = getChannelType(contact)
        const agentName = contact.agentName ?? contact.attributes['sa-agent-name']

        return { ...contact, interactionDuration, channelType, attributes, agentName }
    })
}

export const getThreadMessageGroups = async (contacts: ContactState[]) => {
    const threadIds = [
        ...new Set(
            contacts.map(
                (contact) =>
                    contact.attributes['sa-parent-thread-id'] || contact.attributes['sa-threadID'],
            ),
        ),
    ]
    const threadMessageGroups = await getThreads(threadIds)
    return threadMessageGroups
}

export const getContactHistoryIdsFromOtherParties = (
    threadMessageGroups: AllThreadsAndMessages[],
    contacts: ContactState[],
) => {
    const otherPartiesContactId: string[] = threadMessageGroups.flatMap((messageGroup) => {
        return messageGroup.messages.flatMap((message) => {
            if (message.contactID) {
                return contacts.find((contact) => contact.ID === message.contactID)
                    ? []
                    : [message.contactID]
            }
            return []
        })
    })
    return [...new Set(otherPartiesContactId)]
}

export const isVoiceContactWithoutPhoneNumber = ({
    customerEndpointAddress,
    channel,
}: ContactState): boolean => {
    return !customerEndpointAddress && channel === connect.ChannelType.VOICE
}

export const isChannelOfCategory = <T extends ChannelCategory>(
    channel: ChannelType,
    channelCategory: T,
): channel is ChannelCategoryMap[T] => {
    return channelsByCategory[channelCategory].includes(channel)
}
